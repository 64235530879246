<template>
  <div class="order border border-black rounded">
    <h4>Информамация о заказе</h4>
    <div v-if="isActiveOrder">
      <p @click="getOrder()" class="orderColor">ID: {{ orderDirvers.id }}</p>
      <p>Тариф: {{ orderDirvers.tariff }}</p>
      <p>Статус: {{ orderDirvers.status }}</p>
      <p>Цена водителя: {{ orderDirvers.price }}</p>
      <p>ВП: {{ orderDirvers.past_minute }}</p>
      <p>Расстояние: {{ orderDirvers.distance }}</p>
      <p>Расстояние(По городу): {{ orderDirvers.distance_in_city }}</p>
      <p>Расстояние(Межгород): {{ orderDirvers.search_address_id }}</p>
      <p>Холостой: {{ orderDirvers.free_km }}</p>
      <p>Маршрут:</p>
      <p>
        >
        <span>{{ orderDirvers.from_address.address }}</span
        ><br />
        >
        <span v-for="(to_address, idx) in orderDirvers.to_addresses" :key="idx">
          <span v-if="to_address">
            {{ to_address.address }}
          </span>
        </span>
      </p>
      <p>
        {{
          `${
            orderDirvers.info_for_operator ? orderDirvers.info_for_operator : ""
          } ${
            orderDirvers.info_for_drivers ? orderDirvers.info_for_drivers : ""
          } `
        }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isActiveOrder", "orderDirvers", "order"],
  methods: {
    getOrder() {
      this.$router.push({
        name: "infoOrderMap",
        query: { order: this.order.id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Закази " + this.order.id,
        `/infoOrderMap`,
        this.order.id,
      ]);
    },
  },
};
</script>

<style scoped>
.orderColor {
  color: blue;
  font-size: 15px;
  cursor: pointer;
}

.order {
  margin-top: 20px;
  margin-left: 12px;
  margin-right: 12px;
  padding: 10px;
}
</style>
